import styled from 'styled-components';

export const MainDiv = styled.div`
  color: #666;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const SpanStyle = styled.span`
  position: relative;
  top: -105px;
  @media (max-width: 991px) {
    top: -80px;
  }
  @media (max-width: 767px) {
    top: -70px;
  }
`;

export const LinkStyle = styled.a`
  display: block;
  color: #5ea9dd;
  text-decoration: none;
  :hover {
    color: #5ea9dd;
    text-decoration: none;
  }
`;

export const ImageStyle = styled.img`
  float: left;
  width: 31px;
  height: auto;
  margin-right: 10px;
  @media (max-width: 400px) {
    width: 60% !important;
  }
`;
