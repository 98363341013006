import React from 'react';
import * as Styled from './thankyouCallAccountStyle';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';
import { useEffect } from 'react';

const ThankYouCallAccount = ({ sectionData, location }) => {
  useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });
      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.MainDiv>
      {sectionData?.elementId?.trim() && (
        <Styled.SpanStyle
          id={sectionData?.elementId?.trim()}
        ></Styled.SpanStyle>
      )}
      <Styled.LinkStyle
        href={
          isExternalUrlHref(sectionData?.path)
            ? sectionData?.path
            : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + sectionData?.path
              )
        }
        target={isExternalUrl(sectionData?.path) ? '_blank' : '_self'}
      >
        {sectionData?.richText &&
          documentToReactComponents(
            JSON.parse(sectionData?.richText?.raw),
            optionsMainStyle
          )}
      </Styled.LinkStyle>
    </Styled.MainDiv>
  );
};
export default ThankYouCallAccount;
